<template>

    <div class="">

        <div class="mb-4">
            <div class="fs-4 p-1 d-flex align-items-center justify-content-center bg-black text-white">
                <div class="ps-1 text-uppercase">
                    restore purchased upgrade
                </div>
            </div>
        </div>

        <div class="m-5 card text-center" v-if="restoreTry">
            <div class="card-header text-uppercase">
                Upgrade Details
            </div>
            <div class="card-body">
                Already purchased the full game upgrade? 
            </div>
            <div class="card-footer text-muted">
                <button class="btn btn-primary btn-sm text-uppercase clickfx" @click="restoreIAP()">Restore Purchase</button>
            </div>
        </div>

        <div class="m-5 card text-center" v-if="!restoreTry">
            <div class="card-header text-uppercase">
                Upgrade Details
            </div>
            <div class="card-body">
                We couldn't find an existing purchase for this upgrade. Are you signed into the same <span  v-if="$parent.platform === 'ios'">Apple ID</span><span v-else>Google Account</span> which purchased the upgrade?
            </div>
            <div class="card-footer text-muted">
                <a href="mailto:aaron@playjustsaying.com" class="text-decoration-none fw-bold">Please contact us</a> for a code to enable your upgrade. Thank you.
                <div class="mt-2 d-flex align-items-center justify-content-between">
                    <input v-model="promoCode" class="form-control" type="text" style="width:6rem;"/>
                    <button type="button" class="btn btn-primary btn-sm text-uppercase" @click="submitPromo()">submit</button>
                </div>
            </div>
        </div>

    </div>

</template>

<script>
import { useUserStore,useGameStore } from '@/gamestore.js';


export default {
    components: { 
    },
    data: () => ({  
        userStore: useUserStore(),
        gameStore: useGameStore(),
        restoreTry: 1,
        promoCode: '',
    }),
    methods: {
        restoreIAP: function() {
            var self = this;
            self.$parent.restoreIAP();

            setTimeout(function(){
                self.restoreTry = 0;
            },2000);
        },
        submitPromo: function() {
            var postData = {
                action: 'promoCode',
                promoCode: this.promoCode,
            };

            fetch(this.$root.remoteAjax, {
                method: 'post',
                body: JSON.stringify(postData),
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*'
                }
            }).then((response) => response.json())
            .then((data) => {
                if ( data.ok ) {
                    localStorage.setItem("IAP_isPaid", "1");
                    this.userStore.users.paidMode = 'unlimited';
                    this.$parent.view('paythanks');
                } else {
                    alert('Promo Code does not seem to be valid.');
                }
            });
        },
    },
    mounted() {

    },
}

</script>

<style>

</style>