<template>
    <div class="">

        <userhud ref="userhud" />

        <div class="mb-4">
            <div class="fs-4 p-1 d-flex align-items-center justify-content-center bg-black text-white">
                <div class="ps-1 text-uppercase">
                    <span>puzzle library</span>
                </div>
            </div>
        </div>

        <div class="px-2" style="overflow:hidden">
            <div class="mt-2 d-flex align-items-start justify-content-between mt-3">
                <div>
                    <div class="text-uppercase fs-4">
                        {{ gameStore.options.sources.full }}
                    </div>
                    <div class="fs-6 text-secondary">
                        Includes puzzles from American English, British English, including obscure or historical sources.
                    </div>
                </div>
                <div class="ms-5">
                    <div class="form-check form-switch">
                        <input class="form-check-input" type="checkbox" role="switch" data-source="full" :checked="userStore.user.options.source=='full'" @change="updateSource($event)">
                    </div>
                </div>
            </div>  
            <div class="mt-2 d-flex align-items-start justify-content-between mt-3">
                <div>
                    <div class="text-uppercase fs-4">
                        {{ gameStore.options.sources.jr }}
                    </div>
                    <div class="fs-6 text-secondary">
                        Puzzles better suited to younger players or anyone learning American English.
                    </div>
                </div>
                <div class="ms-5">
                    <div class="form-check form-switch">
                        <input class="form-check-input" type="checkbox" role="switch" data-source="jr" :checked="userStore.user.options.source=='jr'" @change="updateSource($event)">
                    </div>
                </div>
            </div>                    
        </div>

        <div class="mt-5 text-center back-arrow">
            <a href="#" class="text-decoration-none clickfx fs-1" @click="$root.view('player')">
                <i class="game-icon game-icon-lob-arrow"></i>
            </a>
        </div>

        <alert-dialog ref="alertDialog"></alert-dialog>
        <confirm-dialog ref="confirmDialog"></confirm-dialog>
    </div>

</template>

<script>
import { useUserStore,useGameStore } from '@/gamestore.js';
import userhud from "@/components/userhud.vue";
import alertDialog from '@/components/alertdialog.vue';
import confirmDialog from '@/components/confirmdialog.vue';

export default {
  name: 'source',
  components: { 
        userhud, alertDialog, confirmDialog
    },  
  props: {
    
  },
  data: () => ({   
      userStore: useUserStore(),
      gameStore: useGameStore(),
  }),
  computed: {

  },
  mounted() {
  },
  methods: {
    updateSource: function(event) {
        if ( event.srcElement.checked ) {
            this.userStore.user.options.source = event.srcElement.attributes['data-source'].value;
            return;
        } else {
            event.srcElement.checked = true;
            this.userStore.user.options.source = event.srcElement.attributes['data-source'].value;
            return;
        }
        
    },   
  },
}
</script>

<style>
.form-switch {
    transform: scale(1.5);
    transform-origin: top;
}

.back-arrow {
    transform: scale(2) rotate(90deg);
    transform-origin: center;
}

</style>