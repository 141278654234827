import { defineStore } from 'pinia';
import sfxLib from '@/sfx.js';

var defaultOptions = {
    diff: 1,
    ppr: 5,
    source: 'jr',
    timer: 0,
};

var globalOptions = {
    sources: {
        'full': 'All The Puzzles',
        'jr': 'Student',
    },
    ppr: {
        1: {
            color: '#FFE5AD',
        },
        3: {
            color: '#0079FF',
        },
        5: {
            color: '#cc1d6c',
        },
        10: {
            color: '#7334fa',
        },
        15: {
            color: '#fab908',
        }
    },
    puzzleValue: {
        length: 0,
        stopWord: 2,
        guessWord: 15,
        diffHi: 1.25,
        diffLo: 0.75,
    }
}

var defaultUser = {
    id: 1,
    avatar: 0,
    bust: 0,
    name: 'Guest Player',
    options: JSON.parse(JSON.stringify(defaultOptions)),
    tools: {
        shield: {
            id: 'shield',
            name: 'Immunity Shield',
            total: 1,
            active: 0,
            sound: {
                on: sfxLib.sfxLib.shieldUp,
                off: sfxLib.sfxLib.toolOff,
                hit: sfxLib.sfxLib.shieldBlock,
            }
        },
        wand: {
            id: 'wand',
            name: 'Solving Wand',
            total: 0,
            active: 0,
            sound: {
                on: sfxLib.sfxLib.wandOn,
                hit: sfxLib.sfxLib.wandActivate,
            }
        },
    },
    totals: {
        score: 0,
        rounds: 0,
        puzzles: 0,
        guessRight: 0,
        guessWrong: 0,
        guessTotal: 0,
        successRate: 0,
        perfectGames: [],
    }
};

var allUsers = {
    appMode: '',
    paidMode: 'free',
    active: 1,
    maxUsers: {
        free: 100,
        paid: 100,
    },
    globalVolume: 100,
    users: {
        1 : JSON.parse(JSON.stringify(defaultUser)),
    },
};

var defaultBonus = {
    diff: {
        1: {
            min: 50,
            factor: 1,
            timer: 5,
            award: {
                icon: 'game-icon game-icon-allied-star',
                wins: 2,
            }
        },
        2: {
             min: 75,
             factor: 3,
             timer: 10,
             award: {
                icon: 'game-icon game-icon-sunrise',
                wins: 2,
            }
        },
        3: {
            min: 90,
            factor: 5,
            timer: 20,
            award: {
                icon: 'game-icon game-icon-trophy',
                wins: 2,
            }
       },
    },
    immunityScore: 5,
    immunityPrice: 200,
    immunityQtyDiscount: 0.80,
    wandPrice: 800,
    userStyle: 25,
    perfectSolve: 5,
    perfectGame: 3,
};

var defaultPenalty = {
    diff: {
        1: {
            successRate: 20,
            tax: 10,
            timer: 5,
        },
        2: {
            successRate: 40,
            tax: 15,
            timer: 10,
        },
        3: {
            successRate: 60,
            tax: 25,
            timer: 20,
        },
    },
}

var defaultGame = {
    inProgress: 0,
    isPaused: 0,
    totalScore: 0,
    totalScoreMax: 0,
    totalScoreProgress: 0,
    bonusHistory: {},
    penaltyHistory: [],
    timePenalty: 0,
    timeBonus: 0,
    guessRight: 0,
    guessWrong: 0,
    guessTotal: 0,
    puzzleIds: [],
    puzzleIdx: 0,
    puzzle: {},
    roundComplete: 0,
    clueCount: 4,
    gameboard: {
        isStart: 0,
        isWin: 0,
        isReady: 0,
        token: 1,
        answer: -1,
        cluelength: 0,
        guessCount: 0,
        clueTimer: {
            started: 0,
            time: 0,
            handle: 0,
            init: {
                diff: {
                    1: 30,
                    2: 20,
                    3: 10,
                }
            }
        },
    }
}

export const useUserStore = defineStore('userStore',{
    state: () => ({
      users: JSON.parse(JSON.stringify(allUsers)),
    }),
    persist: {
        enabled: true,
    },
    getters: {
      user: function() {
          return this.users ? this.users.users[this.users.active] : {};
      },
      otherUsers: function() {
          var usersOther = JSON.parse(JSON.stringify(this.users.users));
          //delete usersOther[1];
          delete usersOther[this.users.active];
          return usersOther;
      },
    }, 
    actions: {
      genID: function() {
          return Date.now().toString(36) + Math.random().toString(36).substring(2);
      },
      newUser: function(options) {
          var userNew = JSON.parse(JSON.stringify(defaultUser));
          userNew.id = this.genID();
  
          if ( options.name ) {
              userNew.name = options.name;
          }
  
          if ( options.hasOwnProperty('avatar') ) {
              userNew.avatar = options.avatar;
          }
  
          this.users.users[userNew.id] = userNew;
          this.users.active = userNew.id;

          return userNew.id;
      },
      deleteUser: function(id) {
          delete this.users.users[id];
      },
      resetUsers: function() {
        this.users = JSON.parse(JSON.stringify(allUsers));
        location="/";
      },
      chargeUser: function(amt) {
        this.user.totals.score = Number(this.user.totals.score) - Number(amt);
      },
    }
  });

export const useGameStore = defineStore('gameStore',{
  state: () => ({
    options: JSON.parse(JSON.stringify(globalOptions)),
    bonus: JSON.parse(JSON.stringify(defaultBonus)),
    penalty: JSON.parse(JSON.stringify(defaultPenalty)),
    currentGame: JSON.parse(JSON.stringify(defaultGame)),
    currentPuzzles: [],
  }),
  persist: false,
  getters: {

  }, 
  actions: {
    resetGame: function(data) {
        var defaults = JSON.parse(JSON.stringify(defaultGame));
        Object.assign(defaults, data);

        this.currentGame = defaults;
        this.currentPuzzles = [];
    },
  }
});
