<template>
    <div class="text-center">
      <button type="button" class="btn btn-primary" @click="startGame();" v-if="!gameStore.currentGame.gameboard.isStart">
          Start Game
      </button>
    </div>
</template>

<script>
import { useGameStore } from '@/gamestore.js';

export default {
  components: {

  },
  data () {
    return {
      gameStore: useGameStore(),
    }
  },
  methods: {
    startGame: function(){
      this.$root.view('setup');
    },
  },
  mounted() {
    //this.debugOut('home',this.gameStore.currentGame);
  },
}

</script>