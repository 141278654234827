<template>

    <div class="text-center">
        <img src="@/assets/gfx/ohnobubble.svg" style="width:90%" class="anim pulsate-fwd" />
        <h1 class="mt-2 text-primary text-uppercase">Offline</h1>
        <h4 class="text-secondary">
            You can continue playing when your connection is online again!
        </h4>
        <div class="mt-5 text-center back-arrow">
            <a href="#" class="text-decoration-none clickfx fs-1" @click="$root.view('player')">
                <i class="game-icon game-icon-lob-arrow"></i>
            </a>
        </div>
    </div>

</template>

<script>
export default {
    components: { 
    },
    data: () => ({  
    }),
    methods: {
        
    }
}
</script>

<style>
</style>