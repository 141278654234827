export default {
    getGame: function (dbHandle,options) {
        if ( options.puzzleId ) {
            var data = dbHandle.prepare( "SELECT * FROM puzzles WHERE id in (" + options.puzzleId.join(',') + ")" );
        } else {
            var sql = "SELECT * FROM puzzles WHERE ";
            var ands = [];

            if ( options.diff == 1 ) {
                ands.push(" (diff>=200 AND length<=2) ");
            } else if ( options.diff == 3 ) {
                ands.push(" (diff<=50 AND length>=3) ");
            }

            if ( options.source == 'jr' ) {
                ands.push(" jr=1 ");
            } else {
                ands.push(" jr=0 ");
            }

            // if ( options.appMode == 'native' && options.paidMode == 'free' ) {
            //     ands.push(" free=1 ");
            // }

            sql = sql + ands.join(" AND ");
            sql = sql + " ORDER BY RANDOM() ";
            sql = sql + " LIMIT " + options.ppr;

        }

        var puzzles = [];
        //console.log(sql);
        var data = dbHandle.prepare(sql);
        while ( data.step() ) {
            puzzles.push( data.getAsObject() );
        };
        return puzzles;
    }
}
