import { Preferences } from "@capacitor/preferences"

const getItem = async key => {
  return Preferences.get({
    key
  }).then(res => {
    if (res && res.value) return JSON.parse(res.value)
    else return res.value
  })
}

const setItem = async (key, value) => {
  return Preferences.set({
    key,
    value: JSON.stringify(value)
  })
}

export const clear = async () => {
  return Preferences.clear()
}

export const removeItem = async key => {
  return Preferences.remove({
    key
  })
}

export const getKeys = async () => {
  return Preferences.keys()
}

const updateStorage = async (store, rules) => {
  const storeKey = store.$id

  if (rules.include || rules.exclude) {
    const paths = rules.include
      ? rules.include
      : Object.keys(store.$state).filter(
          key => rules.exclude.includes(key) === false
        )

    const partialState = paths.reduce((acc, curr) => {
      acc[curr] = store.$state[curr]
      return acc
    }, {})
    setItem(storeKey, partialState)
  } else {
    setItem(storeKey, store.$state)
  }
}

const restoreState = (store, storeKey, rules, onRestored) =>
  new Promise(resolve => {
    getItem(storeKey).then(result => {
      const subscribe = () => {
        store.$subscribe(() => {
          updateStorage(store, rules)
        })
      }
      if (result) {
        store.$patch(result)
        updateStorage(store, rules).then(() => {
          subscribe()
          if (onRestored) onRestored(store)
          return resolve()
        })
      } else {
        subscribe()
        return resolve()
      }
    })
  })

export const piniaCapacitorPersist = async ({ options, store }) => {
  if (options.persist?.enabled !== true) return

  const rules = {
    include: options.persist.include,
    exclude: options.persist.exclude
  }

  const storeKey = store.$id
  store.restored = restoreState(
    store,
    storeKey,
    rules,
    options.persist.onRestored
  )
}
