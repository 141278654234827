<template>
    <div class="wordslot guess-click" :id="id" v-on:click="makeGuess($event)"></div>
</template>
  
<script>
import { useUserStore,useGameStore } from '@/gamestore.js';

  export default {
    name: 'wordslot',
    props: {
      id: String,
      msg: String,
      length: Number,
    },
    data: () => ({   
        userStore: useUserStore(),
        gameStore: useGameStore(),
        track : { }
    }),
    methods: {
        randomString(length, chars) {
            var result = '';
            for (var i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
            return result;
        },
        makeGuess(evt) {
            var self = this;

            if ( self.$parent.tvcAnimate || self.track?.interval) {
                return;
            }

            if ( !evt.srcElement.classList.contains('guess-click') ) {
                return;
            }

            var source = evt.srcElement.closest('.wordslot');
            if ( source.classList.contains('guess-wrong') || source.classList.contains('guess-right') ) {
                return;
            }

            var guess = source.getAttribute('id');
            guess = guess.replace('wordslot','');
            //this.debugOut(guess,this.gameStore.currentGame.gameboard.answer);

            var rm = source.querySelectorAll('.ws-bottom,.ws-nextHalf,.ws-nextFull');
            for (let x = 0; x < rm.length; x++) {
                rm[x].remove();
            }

            this.gameStore.currentGame.gameboard.guessCount++;

            if ( guess == this.gameStore.currentGame.gameboard.answer ) {
                source.classList.add('guess-right');

                if ( this.userStore.user.tools.shield.active ) {
                    this.userStore.user.tools.shield.total--;
                    this.userStore.user.tools.shield.active = 0;
                }

                if ( this.gameStore.currentGame.gameboard.guessCount < this.gameStore.currentGame.clueCount ) {
                    this.gameStore.currentGame.guessRight++;
                    this.gameStore.currentGame.guessTotal++;
                    this.gameStore.currentGame.gameboard.guessRight++;
                    if ( this.$parent.clueTimer.enableBonus ) {
                        this.gameStore.currentGame.timeBonus++;
                    }
                }

                if ( this.userStore.user.tools.shield.active ) {
                    this.userStore.user.tools.shield.total--;
                    this.userStore.user.tools.shield.active = 0;
                }

                self.$parent.sfxObj.wordRight = self.$root.playSfx(self.$parent.sfxObj.wordRight,self.$parent.sfxLib.wordRight,{});               

                setTimeout(function(){
                    self.$parent.advanceToken();
                }, 500);
            } else {
                source.classList.add('guess-wrong');

                if ( this.userStore.user.tools.shield.active ) {
                    this.userStore.user.tools.shield.total--;
                    this.userStore.user.tools.shield.active = 0;
                    this.gameStore.currentGame.shieldBlock = 1;
                    this.$root.playSfx(this.$root.sfxObj.tmp,this.userStore.user.tools.shield.sound.hit,{});

                    setTimeout(function(){
                        self.gameStore.currentGame.shieldBlock = 0;;
                    },3000);
                } else {
                    this.gameStore.currentGame.gameboard.guessWrong++;
                    this.gameStore.currentGame.guessWrong++;
                    this.gameStore.currentGame.guessTotal++;
                    self.$parent.guessWrong();
                }
            }
        },  
        init() {
            //this.debugOut(this.gameStore.currentGame.gameboard.answer)
            this.gameStore.currentGame.gameboard.wrong = 0;
            var speed = .025; 
            this.track.beginStr = this.randomString(this.length, 'ABCDEFGHIJKLMNOPQRSTUVWXYZ').split("");

            var endStr = this.msg.toUpperCase();
            var padTotal = this.length - endStr.length;

            if ( padTotal > 0 ) {
                endStr = endStr.padEnd(padTotal);
            }

            this.track.endStr = endStr.split("");
            var x = 0;
            var amountOfFlaps;

            amountOfFlaps = (this.track.beginStr.length >= this.track.endStr.length) ? this.track.beginStr.length : this.track.endStr.length;

            var div = document.getElementById(this.id);
            var slotPos = this.id.replace('wordslot','');

            var html = "";
            for (x = 0; x < amountOfFlaps; x++) {
                html += '<div class="ws-splitflap guess-click"><div class="ws-top guess-click"></div><div class="ws-bottom guess-click"></div><div class="ws-nextHalf guess-click"></div><div class="ws-nextFull guess-click"></div></div>';
            }

            div.innerHTML = html;


            this.track.a1 = div.querySelectorAll(".ws-top");
            this.track.a2 = div.querySelectorAll(".ws-bottom");
            this.track.b1 = div.querySelectorAll(".ws-nextFull");
            this.track.b2 = div.querySelectorAll(".ws-nextHalf");

            for (x = 0; x < this.track.a1.length; x++) {
                this.track.a2[x].style.animationDuration = speed + "s";
                this.track.b2[x].style.animationDuration = speed + "s";
            }


            this.track.char = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', '-', "'", '/', '.', ';', ' '];

            this.track.strCount = []; 
            this.track.flag = [];
            this.track.flag2 = false;

            for (x = 0; x < amountOfFlaps; x++) {
            
            if (this.track.beginStr.length != amountOfFlaps)
                {
                for (x = 0; x < amountOfFlaps - this.track.beginStr.length; x++)
                    {
                    this.track.beginStr.push(" ");
                    }
                }
            else if (this.track.endStr.length != amountOfFlaps)
                {
                for (x = 0; x < amountOfFlaps - this.track.endStr.length; x++)
                    {
                    this.track.endStr.push(" ");
                    }
                }
            }
            for (x = 0; x<amountOfFlaps; x++){
                this.track.strCount[x] = this.track.char.indexOf(this.track.beginStr[x]);
                this.track.flag[x] = false; 
                this.track.flag2 = true;
            }

            var maxWidth = document.querySelector('.score-top');
            if ( (maxWidth.offsetWidth - 10) < div.offsetWidth ) {
                document.getElementById('slots').style.setProperty('--ws-scale', (maxWidth.offsetWidth / (div.offsetWidth+20)));
            }

            if (0) {
                var slotsH = document.getElementById('slots').offsetHeight + 20;
                var slotsAreaH = document.getElementById('app').offsetHeight - document.getElementById('phraseWinMarker').offsetTop - document.getElementById('progress-wrapper').offsetHeight;

                if ( slotsAreaH < slotsH ) {
                    var scaleH = slotsAreaH / slotsH;
                    document.getElementById('slots').style.setProperty('--ws-scale', scaleH);
                }
            }


            const self = this; 

            if ( self.$parent.sfxObj.flipBoard == 0 ) {
                self.$parent.sfxObj.flipBoard = self.$root.playSfx(self.$parent.sfxObj.flipBoard,self.$parent.sfxLib.flipBoard,{});
            }

            self.$parent.slotFlipping[slotPos] = 1;
            self.track.interval = setInterval(function() {
                for (x = 0; x < amountOfFlaps; x++) {
                    if (self.track.b1[x].innerHTML == self.track.endStr[x]) {
                        self.dontFlipIt(x);
                    } else {
                        self.flipIt(x);
                    }
                }

                if ( self.track.flag.indexOf(false) == -1 ) {
                    clearInterval(self.track.interval);
                    self.track.interval = 0;
                    if ( Object.keys(self.$parent.slotFlipping).length <= 1 ) {
                        self.$parent.boardReady();
                    }
                    delete self.$parent.slotFlipping[slotPos];
                }
            }, speed * 1000);  
        },
        flipIt(x) {
            this.track.a1[x].innerHTML = this.track.char[(this.track.strCount[x] == 0) ? this.track.char.length - 1 : this.track.strCount[x] - 1];
            this.track.a2[x].innerHTML = this.track.char[(this.track.strCount[x] == 0) ? this.track.char.length - 1 : this.track.strCount[x] - 1];
            this.track.b1[x].innerHTML = this.track.char[this.track.strCount[x]];
            this.track.b2[x].innerHTML = this.track.char[this.track.strCount[x]];

            this.track.a2[x].classList.remove("ws-flip1");
            //a2[x].offsetWidth = a2[x].offsetWidth;
            this.track.a2[x].classList.add("ws-flip1");
            this.track.b2[x].classList.remove("ws-flip2");
            //b2[x].offsetWidth = b2[x].offsetWidth;
            this.track.b2[x].classList.add("ws-flip2");

            if (this.track.strCount[x] > this.track.char.length - 2) this.track.strCount[x] = 0;
            else this.track.strCount[x]++;
        },
        dontFlipIt(x) {
            this.track.flag[x] = true;
            this.track.a2[x].classList.remove("ws-flip2");
            this.track.a2[x].style.backgroundColor = "#3BB6eB";
            this.track.b2[x].style.backgroundColor = "#3BB6eB";
            this.track.a1[x].innerHTML = this.track.char[(this.track.strCount[x] == 0) ? this.track.char.length - 1 : this.track.strCount[x] - 1];
            this.track.a2[x].innerHTML = this.track.char[(this.track.strCount[x] == 0) ? this.track.char.length - 1 : this.track.strCount[x] - 1];
        }
    },
    mounted() {
        document.getElementById('slots').style.setProperty('--ws-scale', 1);
        this.init();
    }
  }


</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style>
    
    .wordslot {
        width: 100%;
        display: flex;
        justify-content: center;
        cursor: pointer;
    }

    .ws-splitflap {
        position: relative;
        min-width: 3rem;
        height: 3rem;
        margin: 0.15rem;
        line-height: 3rem;
        font-size: 2.5rem;
        font-family: Monospace;
        text-align: center;
        color: white;
        border-radius: 0.5rem 0.5rem 0.5rem 0.5rem;
    }

    .ws-top {
        position: relative;
        height: 50%;
        width: 100%;
        border-radius: 0.5rem 0.5rem 0.5rem 0.5rem;
        background-color: #3BB6EB;
        overflow: hidden;
        z-index: 0;
    }

    .wordslot.guess-wrong .ws-splitflap .ws-top {
        height: 100%;
        transition-property: color, background-color;
        transition-duration: 0.5s;
        transition-timing-function: linear;
        background-color: rgb(212, 17, 17);
        color: rgb(224, 222, 222);
    }

    .wordslot.guess-right .ws-splitflap .ws-top {
        height: 100%;
        transition-property: color, background-color,font-weight;
        transition-duration: 0.5s;
        transition-timing-function: linear;
        background-color: rgb(49, 212, 17);
        color: white;
        font-weight: bold;
    }

    .ws-bottom {
        position: relative;
        height: 100%;
        width: 100%;
        margin-top: -50%;
        border-radius: 0.5rem 0.5rem 0.5rem 0.5rem;
        z-index: -1;
        background-color: black;
        background-image: linear-gradient(rgba(59, 182, 235, 0), #3BB6EB);
        transform-origin: center;
    }

    .ws-nextHalf {
        position: relative;
        height: 50%;
        width: 100%;
        margin-top: -100%;
        overflow: hidden;
        border-radius: 0.5rem 0.5rem 0 0;
        z-index: 2;
        background-color: black;
        background-image: linear-gradient(#3BB6EB, rgba(59, 182, 235, 0));
        transform-origin: bottom;
    }

    .ws-nextFull {
        position: relative;
        height: 100%;
        width: 100%;
        background-color: #3BB6EB;
        margin-top: -50%;
        border-radius: 0.5rem 0.5rem 0.5rem 0.5rem;
        z-index: -3;
    }

    .ws-flip1 {
        animation: flip1 ease-in 1;
        animation-duration: 1s;
    }

    .ws-flip2 {
        animation: flip2 ease-out 1;
        animation-duration: 1s;
    }

    @keyframes flip1 {
        0% {
            transform: rotateX(0deg);
            background-color: #3BB6EB;
        }
        50% {
            transform: rotateX(90deg);
            background-color: black;
        }
        100% {
            transform: rotateX(90deg);
        }
    }

    @keyframes flip2 {
        0% {
            transform: rotateX(-90deg);
        }
        50% {
            transform: rotateX(-90deg);
        }
        100% {
            transform: rotateX(0deg);
            background-color: #3BB6EB;
        }
    }
  </style>
  