<template>

    <div class="">

        <userhud />

        <div class="mb-4">
            <div class="fs-4 p-1 d-flex align-items-center justify-content-center bg-black text-white">
                <div class="ps-1 text-uppercase">
                    let's play a game
                </div>
            </div>
        </div>

        <div class="m-2">
            <div class="d-flex align-items-center justify-content-between">
                <div class="text-uppercase fs-4">difficulty</div>
                <div class="btn-group diff" role="group" >
                    <input type="radio" class="btn-check" name="setup_diff" id="diff_2" autocomplete="off" value="2" v-model="diff">
                    <label class="btn btn-outline-primary text-uppercase fs-6 me-1" for="diff_2">random</label>
                    
                    <input type="radio" class="btn-check" name="setup_diff" id="diff_1" autocomplete="off" value="1" v-model="diff">
                    <label class="btn btn-outline-primary text-uppercase ps-3 fs-6" for="diff_1">easy</label>

                    <input type="radio" class="btn-check" name="setup_diff" id="diff_3" autocomplete="off" value="3" v-model="diff">
                    <label class="btn btn-outline-primary text-uppercase pe-3 fs-6" for="diff_3">hard</label>
                </div>
            </div>

            <div class="d-flex align-items-center justify-content-between mt-3">
                <div class="text-uppercase fs-4">Puzzle Count</div>
                <div class="btn-group puzzlecount" role="group" >
                    <input type="radio" class="btn-check" name="setup_puzzles" id="puzzles_0" autocomplete="off" value="3" v-model="ppr">
                    <label class="btn btn-outline-primary" for="puzzles_0">3</label>

                    <input type="radio" class="btn-check" name="setup_puzzles" id="puzzles_1" autocomplete="off" value="5" v-model="ppr">
                    <label class="ms-2 btn btn-outline-primary" for="puzzles_1">5</label>

                    <input type="radio" class="btn-check" name="setup_puzzles" id="puzzles_2" autocomplete="off" value="10" v-model="ppr">
                    <label class="ms-2 btn btn-outline-primary" for="puzzles_2">10</label>

                    <input type="radio" class="btn-check" name="setup_puzzles" id="puzzles_3" autocomplete="off" value="15" v-model="ppr">
                    <label class="ms-2 btn btn-outline-primary" for="puzzles_3">15</label>
                </div>
            </div>

            <div class="d-flex align-items-center justify-content-between mt-3">
                <div class="text-uppercase fs-4">use timer</div>
                <div class="btn-group diff" role="group" >
                    <input type="radio" class="btn-check" name="setup_timer" id="timer_2" autocomplete="off" value="0" v-model="timer">
                    <label class="btn btn-outline-primary text-uppercase fs-6 me-2" for="timer_2">no</label>
                    
                    <input type="radio" class="btn-check" name="setup_timer" id="timer_1" autocomplete="off" value="1" v-model="timer">
                    <label class="btn btn-outline-primary text-uppercase ps-3 fs-6" for="timer_1">yes</label>
                </div>
            </div>
        </div>

        <div v-if="showScorecard" id="scorecard" class="mt-4" v-on:click.stop="closeScorecard()">
            <div :class="{ 'anim bounce-in-top' : showScorecard==1, 'anim puff-out-center' : showScorecard==2 }">
                <div class="mb-4">
                    <div class="fs-5 p-1 d-flex align-items-center justify-content-center bg-black text-white">
                        <div class="ps-1 text-uppercase">
                            scoring
                        </div>
                    </div>

                    <div class="text-center fs-5 text-secondary">
                        Change play style above to update scoring
                    </div>
                </div>

                <div class="m-2">
                    <div class="d-flex align-items-start justify-content-between mt-1">
                        <div class="d-flex flex-column">
                            <div class="text-uppercase fs-5 text-success">Perfect Game</div>
                            <div class="text-secondary">Solve all puzzles with 100% success</div>
                            <div class="d-flex align-items-center text-secondary">
                                <i class="fs-1 me-2" :class="gameStore.bonus.diff[userStore.user.options.diff].award.icon" :style="'color: ' + gameStore.options.ppr[userStore.user.options.ppr].color"></i> award every {{gameStore.bonus.diff[userStore.user.options.diff].award.wins  }}
                            </div>
                        </div>
                        <div class="fs-4 text-success text-end d-flex flex-column">
                            + {{ $root.calcBonus('base-perfect') + $root.calcBonus('immunity') }}
                            <span class="fs-6">+1 shield</span>
                        </div>
                    </div>
                    <div class="d-flex align-items-start justify-content-between mt-2">
                        <div>
                            <div class="text-uppercase fs-5 text-success">Perfect Solve</div>
                            <p class="text-secondary">Solve a single puzzle without errors</p>
                        </div>
                        <div class="fs-4 text-success">
                            + {{ $root.calcBonus('perfectsolve') }}
                        </div>
                    </div>
                    <div class="d-flex align-items-start justify-content-between mt-1">
                        <div>
                            <div class="text-uppercase fs-5 text-success">High Performer</div>
                            <p class="text-secondary">Complete game with at least {{ gameStore.bonus.diff[userStore.user.options.diff].min }}% success</p>
                        </div>
                        <div class="fs-4 text-success">
                            + {{ $root.calcBonus('base') }}
                        </div>
                    </div>
                    <div class="d-flex align-items-start justify-content-between mt-1" v-if="userStore.user.options.timer">
                        <div>
                            <div class="text-uppercase fs-5 text-success">Time Bonus</div>
                            <p class="text-secondary">Solve clue within bonus time <i class="text-success fs-3 game-icon game-icon-stopwatch"></i></p>
                        </div>
                        <div class="fs-4 text-success">
                            + {{ gameStore.bonus.diff[userStore.user.options.diff].timer }}
                        </div>
                    </div>
                    <div class="d-flex align-items-start justify-content-between mt-1">
                        <div>
                            <div class="text-uppercase fs-5 text-danger">Performance Penalty</div>
                            <p class="text-secondary">Complete game below {{ gameStore.penalty.diff[userStore.user.options.diff].successRate }}% success</p>
                        </div>
                        <div class="fs-4 text-danger d-flex flex-column align-items-end">
                            <span>- {{ $root.calcPenalty() }}</span>
                        </div>
                    </div>
                    <div class="d-flex align-items-start justify-content-between mt-1" v-if="userStore.user.options.timer">
                        <div>
                            <div class="text-uppercase fs-5 text-danger">Time Penalty</div>
                            <p class="text-secondary">Solve clue after timer expires <i class="text-danger fs-3 game-icon game-icon-stopwatch"></i></p>
                        </div>
                        <div class="fs-4 text-danger d-flex flex-column align-items-end">
                            <span>- {{ gameStore.penalty.diff[userStore.user.options.diff].timer }}</span>
                        </div>
                    </div>
                </div>

                <div class="d-flex justify-content-center mb-2" >
                    <i class="fs-1 game-icon game-icon-cancel icon-md"  v-on:click.stop="closeScorecard()"></i>
                </div>
            </div>
        </div>

        <div class="mt-5 text-center" v-if="!showScorecard">
            <button type="button" class="btn btn-lg btn-success text-white text-uppercase anim marquee position-relative" @click="startGame()">
                <span></span><span></span><span></span><span></span>
                Let's Go
            </button>
        </div>

        <div class="mt-5 text-center" v-if="!showScorecard">
            <a href="#" class="text-uppercase clickfx" v-on:click.stop="showScorecard=1">how does scoring work?</a>
        </div>
    </div>

</template>

<script>
import userhud from "@/components/userhud.vue";
import { useUserStore,useGameStore } from '@/gamestore.js';

export default {
    components: { 
        userhud
    },
    data: () => ({  
        userStore: useUserStore(),
        gameStore: useGameStore(),
        showScorecard: 0,
    }),
    computed: {
        diff: {
            get() {
                return this.userStore.user.options.diff;
            },
            set(val) {
                this.userStore.user.options.diff = Number(val);
            }
        },
        ppr: {
            get() {
                return this.userStore.user.options.ppr;
            },
            set(val) {
                this.userStore.user.options.ppr = Number(val);
            }
        },
        timer: {
            get() {
                return this.userStore.user.options.timer;
            },
            set(val) {
                this.userStore.user.options.timer = Number(val);
            }
        }
    },
    methods: {
        startGame: function(){
            if ( this.$parent.isPaid() ) {
                this.$root.view('play');    
            } else {
                if ( this.userStore.users.freeplays > 8 ) {
                    this.$root.view('play');    
                } else {
                    this.$root.view('upgrade');
                }
            }
            
        },
        closeScorecard: function() {
            this.showScorecard=2;
            var self = this;

            setTimeout(function(){
                self.showScorecard = 0;
            },250);
        }
    },
    mounted() {
        this.gameStore.currentGame.gameboard.isStart = 0;
    },
}

</script>

<style>
label[for='diff_1'] {
    border-top-left-radius: 50% !important;
    border-bottom-left-radius: 50% !important;
}

label[for='diff_2'] {
    border-top-right-radius: var(--bs-btn-border-radius) !important;
    border-bottom-right-radius: var(--bs-btn-border-radius) !important;
}

label[for='diff_3'] {
    border-top-right-radius: 50% !important;
    border-bottom-right-radius: 50% !important;
}

.puzzlecount label {
    border-radius: 50% !important;
    width: 2.5rem;
}

#scorecard {
    transform: scale(0.8);
    position: absolute;
    top: 15%;
    width: 100%;
    z-index:100;
    left:0;
}

#scorecard > div {
    background-color: #FFF;
    border:1px solid #000;
    box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.75);
    overflow-y: auto;
}
</style>