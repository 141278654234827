import './assets/css/bootstrap.custom.scss';
import { createApp } from 'vue';
import { createPinia } from 'pinia';
import { piniaCapacitorPersist } from '@/pinia-capacitor.js';
//import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
import { SplashScreen } from '@capacitor/splash-screen';
import { TextZoom } from "@capacitor/text-zoom";

import App from './App.vue';
import { useUserStore } from '@/gamestore.js';
import { createHead } from 'unhead';
import { useHead } from 'unhead';
import {Howl, Howler} from 'howler';
import './assets/css/global.css';
import './assets/css/anim.css';
import './assets/css/game-icons.css';

import "cordova-plugin-purchase";

import { Device } from '@capacitor/device';

const pinia = createPinia();
pinia.use(piniaCapacitorPersist);

createHead();
const app = createApp(App);
app.use(pinia);

import initSqlJs from "sql.js";
initSqlJs({
  locateFile: file => `/assets/sql-wasm.wasm`
}).then(function(SQL){
  const db = new SQL.Database();
  const xhr = new XMLHttpRequest();
  xhr.open('GET', '/assets/gamedata.db', true);
  xhr.responseType = 'arraybuffer';
  xhr.onload = e => {
    const uInt8Array = new Uint8Array(xhr.response);
    const db = new SQL.Database(uInt8Array);
    app.config.globalProperties.dbHandle = db;
  };
  xhr.send();
});

app.config.globalProperties.debugOut = function debugOut(...args) {
  if ( process.env.NODE_ENV.match(/dev/) ) {
    console.log(args);
  }
}

async function webSetup() {
  let info = await Device.getInfo();
  if ( info.operatingSystem.match(/android/i) || info.operatingSystem.match(/ios/i) ) {
    location = "/mobile.html";
  } else {
    kickoff();
  }
}

async function kickoff() {
  const userStore = useUserStore();
  await userStore.restored;

  if ( location.search == '?reset' ) {
    userStore.resetUsers();
    location = "/";
  }

   if ( Capacitor.isNativePlatform() ) {
    userStore.users.appMode = 'native';
  } else {
    userStore.users.appMode = 'web';
  }

  app.mount('#app');

  useHead({
    title: "Just Saying",
    meta: [
      { name: 'description', content: "The game for idioms" },
    ], 
  });
}

await SplashScreen.hide();
await SplashScreen.show({
  showDuration: 1000,
  autoHide: true,
  androidScaleType: 'FIT_CENTER',
});

document.addEventListener('deviceready', function(){
  TextZoom.set({value: 1.0}); 
});

if ( Capacitor.isNativePlatform() ) {
  kickoff();
} else {
  webSetup();
}