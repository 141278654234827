<template>
    <div id="footer">

        <div v-if="$parent.isPaid()" class="footer w-100 px-2 py-2 text-uppercase d-flex justify-content-center text-white">
            <span>Just Saying - the game for idioms</span>
            <a href="#" class="ms-2 text-decoration-none text-white clickfx" @click="this.$root.view('about')"><i class="fs-3 game-icon game-icon-help"></i></a>
        </div>

        <div v-else id="freebanner" class="p-2 text-uppercase">
            <div class="d-flex align-items-center justify-content-between">
               <a href="#" class="ms-2 text-decoration-none clickfx" @click="this.$root.view('about')">
                <span>Just Saying - the game for idioms</span>
                <i class="ms-2 fs-4 game-icon game-icon-help"></i>
              </a>
              <a href="#" class="text-decoration-none clickfx" @click="$root.view('upgrade')">
                <div class="d-flex align-items-center">
                  <span class="text-danger fs-2 anim jello-horizontal">{{ freePlays() }}</span>
                  <span class="ms-1">free plays remaining</span>
                </div>
              </a>
              <a href="#" class="text-decoration-none clickfx" @click="$root.view('upgrade')">
                <div class="d-flex align-items-center">
                  <span>upgrade now</span>
                  <i class="ms-1 fs-2 game-icon game-icon-play-button"></i>
                  <i class="fs-2 game-icon game-icon-play-button"></i>
                </div>
              </a>
            </div>
        </div>

    </div>
</template>

<script>
import { useUserStore } from '@/gamestore.js';

  export default {
    name: 'footer',
    props: {

    },
    data: () => ({   
      userStore: useUserStore(),
    }),
    methods: {
      freePlays() {
        return this.userStore.users.freeplays;
      }
    }
  }
</script>

<style>
#footer {
    position:absolute;
    bottom: calc(0px + env(safe-area-inset-top));
    width:100%;
}

#footer .footer {
  background-color: var(--bs-primary);
}

#freebanner {
  background-color: #FFFF00;
}
</style>