<template>

    <div style="overflow-y:auto;height:75vh;">

        <div class="text-center">
            <h3 class="text-primary">How to Play</h3>
            <ul class="list-group list-group-flush text-start">
                <li class="list-group-item">
                    Guess the words to complete each phrase!
                </li>
                <li class="list-group-item">
                    Click the player name to edit and create new players.
                </li>
                <li class="list-group-item">
                    Use the immunity shield <i class="fs-5 game-icon game-icon-checked-shield"></i> to block a penalty for guessing the wrong clue.
                </li>
                <li class="list-group-item">
                    Use the solving wand <i class="fs-5 game-icon game-icon-fairy-wand"></i> to solve the clue instantly.
                </li>
                <li class="list-group-item">
                    Press the coins icon <i class="game-icon game-icon-two-coins fs-5"></i> at top of screen to get player upgrades.
                </li>
            </ul>
        </div>

        <hr/>
        <div class="text-center">
            <h4 class="text-primary">Reset Data</h4>
            <p>
                <a href="#" class="text-decoration-none fs-6" @click="resetData()">Click here</a> to delete all players and their data.
            </p>
        </div>

        <hr/>
        <div class="text-center">
            <h4 class="text-primary">Acknowledgments</h4>
            <ul class="list-group list-group-flush text-start">
                <li class="list-group-item text-center">
                    Background music - <a target="_blank" href="https://freemusicarchive.org/music/Origami_Repetika/the-happy-puppy-collection">Origami Repetika</a> and <a target="_blank" href="https://freemusicarchive.org/music/zane-little/a-bag-of-chips">Zane Little</a>
                </li>
                <li class="list-group-item text-center">
                    Game testing and consulting - <a target="_blank" href="https://adamosterweil.com/">Adam Osterweil</a>
                </li>
            </ul>
        </div>

    </div>

    <alert-dialog ref="alertDialog"></alert-dialog>
    <confirm-dialog ref="confirmDialog"></confirm-dialog>
</template>

<script>
import { useUserStore,useGameStore } from '@/gamestore.js';
import alertDialog from '@/components/alertdialog.vue';
import confirmDialog from '@/components/confirmdialog.vue';

export default {
    components: { 
        alertDialog, confirmDialog
    },
    data: () => ({  
        userStore: useUserStore(),
        gameStore: useGameStore(),
    }),
    methods: {
        async resetData() {
            const ok = await this.$refs.confirmDialog.show({
                title: 'Reset All Data',
                message: 'Are you sure you want to permanently delete all players?',
                okButton: 'Delete',
            });
            // If you throw an error, the method will terminate here unless you surround it wil try/catch
            if (ok) {
                this.userStore.resetUsers();
                this.$root.view('setup');
            } else {
                //bail
            }
        },         
    }
}
</script>

<style>
</style>