<template>

  <div class="container p-0 h-100">
    <div class="col-12 h-100 position-relative" :class="{ 'busted' : page=='bust' }" id="app-content">

      <nav class="navbar sticky-top navbar-expand-lg navbar-light bg-primary">
        <div class="px-2 w-100 d-flex align-items-center justify-content-between">
            <a class="navbar-brand text-white text-decoration-none clickfx" href="#" @click="this.$root.view('setup')">
              <img class="my-1" src="@/assets/gfx/justsaying-logo.png" style="height:40px;filter:drop-shadow(0px 0px 6px #222);" />
            </a>

            <div class="d-flex align-items-center justify-content-end">

              <div v-if="platform=='web' && !globalVolumeShow" class="d-flex align-items-center me-4">
                <a class="nav-link text-white text-uppercase me-1 clickfx" target="_blank" href='https://play.google.com/store/apps/details?id=com.bordella.justsaying&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
                  <img style="height: 40px" alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'/>
                </a>
                <a class="nav-link text-white text-uppercase clickfx" target="_blank" href='https://apps.apple.com/us/app/just-saying-idiom-edition/id6463743765'>
                  <img style="height: 28px" alt='Get it on Apple App Store' src='@/assets/gfx/appstore.png'/>
                </a>
              </div>

              <a v-if="0 && userStore.user.totals.score && !globalVolumeShow" class="nav-link text-white text-uppercase me-3 clickfx" href="#" @click="view('shop')">
                <i class="game-icon game-icon-healing text-white fs-3"></i>
              </a>

              <div class="position-relative">
                <div style="cursor:pointer;" @click="globalVolumeShow=!globalVolumeShow">
                  <i v-if="globalVolume>0" class="game-icon game-icon-sound-on text-white fs-3"></i>
                  <i v-if="globalVolume==0" class="game-icon game-icon-sound-off text-white fs-3"></i>
                </div>

                <transition :class="{ 'd-none' : !globalVolumeShow }">
                  <div class="position-absolute" id="globalVolume">
                    <vue3-slider v-model="globalVolume" color="#fff" track-color="#c4cbcf" orientation="horizontal" alwaysShowHandle="true" height="10" handleScale="1.75" @change="changeVolume" @drag-end="globalVolumeShow=0"/>
                  </div>
                </transition>
              </div>
            </div>
          </div>
        </nav>

      <div class="mt-4 px-3" id="app-body">
        <component :is="currentView" :key="componentKey" />
      </div>

      <alert-dialog ref="alertDialog"></alert-dialog>
      <confirm-dialog ref="confirmDialog"></confirm-dialog>

      <myfooter v-if="showFooter()" />

    </div>

  </div>

</template>

<script>
import { App as CapApp } from '@capacitor/app';
import { Network } from '@capacitor/network';
import slider from "vue3-slider";
import footer from "@/components/footer.vue";
import about from "@/views/about.vue";
import home from "@/views/home.vue";
import setup from "@/views/setup.vue";
import player from "@/views/player.vue";
import source from "@/views/source.vue";
import play from "@/views/play.vue";
import shop from "@/views/shop.vue";
import bust from "@/views/bust.vue";
import upgrade from "@/views/upgrade.vue";
import upgrade_restore from "@/views/upgrade_restore.vue";
import paythanks from "@/views/paythanks.vue";
import offline from "@/views/offline.vue";
import notfound from "@/views/404.vue";
import avatarLib from '@/avatars.js';
import sfxLib from '@/sfx.js';
import { useUserStore,useGameStore } from '@/gamestore.js';
import alertDialog from '@/components/alertdialog.vue';
import confirmDialog from '@/components/confirmdialog.vue';

const remoteAjax = "https://playjustsaying.com/backend/ajax.php";

const routeMap = {
  about: about,
  home: home,
  setup: setup,
  player: player,
  source: source,
  play: play,
  shop: shop,
  bust: bust,
  upgrade: upgrade,
  upgrade_restore: upgrade_restore,
  paythanks: paythanks,
  notfound: notfound,
  offline: offline,
};

function decodeBase64(base64) {
    const text = atob(base64);
    const length = text.length;
    const bytes = new Uint8Array(length);
    for (let i = 0; i < length; i++) {
        bytes[i] = text.charCodeAt(i);
    }
    const decoder = new TextDecoder();
    return decoder.decode(bytes);
}

document.addEventListener('deviceready', onDeviceReady);
let IAP = {};
const IAP_ProductId = 'jsunlimited';
let IAP_Platform = '';
let IAP_paycomplete = 0;

function onDeviceReady() {
  this.debugOut('ondeviceready');
  const {store, ProductType, Platform, LogLevel} = CdvPurchase;
  store.verbosity = LogLevel.DEBUG;
  this.debugOut(Capacitor.getPlatform());
  if ( Capacitor.getPlatform() == 'android' ) {
    IAP_Platform = Platform.GOOGLE_PLAY;
  } else {
    IAP_Platform = Platform.APPLE_APPSTORE;
  }
  store.register([{
    type: CdvPurchase.NON_CONSUMABLE,
    id: IAP_ProductId,
    platform: IAP_Platform,
  }]);

  //store.validator = "https://playjustsaying.com/backend/ajax.php";
  //store.validator_privacy_policy = ['analytics', 'support', 'tracking', 'fraud'];

  store.when()
    .productUpdated(refreshIAP)
    .approved(transaction => {
      if (transaction.products && transaction.products[0] && transaction.products[0].id === 'com.bordella.justsaying') {
        transaction.finish();
        return;
      }
      if (transaction.state === 'approved') {
        store.verify(transaction);
      }
    })
    .verified(receipt => {
      finishIAP(receipt);
    });
  store.initialize([IAP_Platform]);
  store.ready(readyIAP);
}

function readyIAP() {
  this.debugOut('IAP ready');
  if ( Capacitor.getPlatform() == 'ios' ) {
    refreshIAP();
  }
}

function finishIAP(receipt) {
  this.debugOut('finishIAP', receipt);

  localStorage.setItem("IAP_isPaid", "1");

  if ( localStorage.getItem("IAP_restore") ) {
    localStorage.removeItem("IAP_restore");
    location="/"; 
  }

  if ( IAP_paycomplete == 1 ) {
    IAP_paycomplete = 0;
    
    receipt.finish();
    this.debugOut('finishedIAP');

    location="/?paythanks";
  }
}

function restoreIAP() {
  this.debugOut('restoreIAP');
  const {store} = CdvPurchase;
  store.restorePurchases();
}

function refreshIAPStore() {
  this.debugOut('refreshIAPStore');
  const {store, ProductType, Platform} = CdvPurchase;
  store.update();
}

function refreshIAP() {
  this.debugOut('refreshIAP', IAP_Platform, IAP_ProductId);
  const {store, ProductType, Platform} = CdvPurchase;
  //this.debugOut('products', store.products);
  IAP = store.get(IAP_ProductId, IAP_Platform);
  this.debugOut('IAP data', IAP.owned, JSON.stringify(IAP));
  //this.debugOut('canpurchase',IAP.canPurchase);
  //this.debugOut(JSON.stringify(IAP.pricing));

  return;
}

export default {
  components: {
    "vue3-slider": slider,
    "myfooter": footer,
    "confirmDialog": confirmDialog,
    "alertDialog": alertDialog,
  },
  data () {
    return {
      isDev: process.env.NODE_ENV.match(/dev/) ? 1 : 0,
      Network: Network,
      CapApp: CapApp,
      platform: Capacitor.getPlatform(),
      avatarLib: avatarLib.avatarLib,
      sfxLib: sfxLib.sfxLib,
      globalVolume: 100,
      globalVolumeShow: 0,
      globalTimer: 0,
      sfxObj: {
        bgTune: 0,
        bgTuneVol: 0.25,
        tmp: 0,
      },
      page: 'setup',
      remoteAjax: remoteAjax,
      userStore: useUserStore(),
      gameStore: useGameStore(),
      componentKey: Math.random(1,10000),
      IAP: IAP,
      viewHistory: [],
    }
  },
  mounted() {
    var self = this;
    // this.Network.addListener('networkStatusChange', status => {
    //   //{connected: true, connectionType: 'wifi'}
    //   //this.debugOut('Network status changed', status);      
    //   if ( !status.connected && self.page != 'offline' ) {
    //     self.view('offline');
    //   }
    // });
    // this.networkStatus();
    
    if ( !this.userStore.users.hasOwnProperty('freeplays') ) {
      this.userStore.users.freeplays = 10;
    }

    this.globalVolume = this.userStore.users.globalVolume;

    this.CapApp.addListener('backButton', ({ canGoBack }) => {
      //App.exitApp();
      //this.debugOut('back button', this.viewHistory);
      
      if ( !this.viewHistory.length ) {
        this.viewHistory = ['setup'];
      }
      
      var v = this.viewHistory[0];

      if ( v == 'play' ) {
        this.view('setup');
      } else {
        this.view(v);
      }
    });

    document.addEventListener("click", e => {
      if ( e.srcElement.classList.contains('clickfx') || e.srcElement.closest('a')?.classList.contains('clickfx') || e.srcElement.closest('button')?.classList.contains('clickfx') ) {
        this.playSfx(this.sfxObj.tmp,this.sfxLib.clickSound,{});
      }
    });

    if ( location.search == '?paythanks' ) {
      window.history.replaceState({}, document.title, "/");
      this.view('paythanks');
    }


  },
  computed: {
    currentView() {
      let component = routeMap[this.page.toLowerCase()];
      if (component) return component;
      return notfound;
    }
  },
  methods: {
    networkStatus: async function() {
      var status = await Network.getStatus();
      //this.debugOut('Network status:', status);
      // if ( !status.connected && this.page != 'offline' ) {
      //   this.view('offline');
      // }
    }, 
    refreshIAP: function(){
      refreshIAP();
    },
    decodeBundle: function(bundle) {
      bundle = bundle.split("").reverse().join("");
      return JSON.parse(decodeBase64(bundle));
    },
    view: async function(v) {
      if ( this.gameStore.currentGame.inProgress && v != 'offline' ) {
        const ok = await this.$refs.confirmDialog.show({
            title: 'Game In Progress',
            message: 'Abort the current game?',
            okButton: 'Yes',
            cancelButton: 'No',
        });
        if (ok) {
          this.gameStore.currentGame.inProgress = 0;
          this.playSfx(this.sfxObj.bgTune,'',{stop:1});
          this.clearGlobalTimer();
          this.updateViewHistory(this.page);
          this.page = v;
        } else {
          //bail
        }
      } else {
        this.playSfx(this.sfxObj.bgTune,'',{stop:1});
        this.clearGlobalTimer();
        this.updateViewHistory(this.page);
        this.page = v;
      }
    },
    updateViewHistory: function(page) {
      this.viewHistory = [page];
    },
    clearGlobalTimer: function() {
      if ( this.globalTimer ) {
        clearInterval(this.globalTimer);
        this.globalTimer = 0;
      }
    },
    kickoffIAP: function() {
      IAP_paycomplete = 1;
      IAP.getOffer().order();
    },
    restoreIAP: function() {
      localStorage.setItem("IAP_restore", "1");
      restoreIAP();
    },
    showFooter: function() {
      if ( 1||this.page == 'setup' ) {
        return true;
      } else {
        return false;
      }
    },
    isPaid: function() {
      this.debugOut('isPaid', localStorage.getItem("IAP_isPaid"), this.userStore.users);
      
      if ( Capacitor.isNativePlatform() ) {
        if ( localStorage.getItem("IAP_isPaid") || this.userStore.users.paidMode == 'unlimited' ) {
          this.userStore.users.paidMode = 'unlimited';
          unset(this.userStore.users.freeplays);
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    },
    restart: function() {
      this.gameStore.resetGame({});
      this.view('setup');
    },
    changeVolume(vol) {
      //this.debugOut(vol/100);
      //Howler.volume(vol/100);
      if ( this.sfxObj.bgTune ) {

        this.sfxObj.bgTune.volume(this.sfxObj.bgTuneVol * (vol/100));
      }
      this.userStore.users.globalVolume = vol;
    },
    shuffle(arr) {
      let shuffled = arr
      .map(value => ({ value, sort: Math.random() }))
      .sort((a, b) => a.sort - b.sort)
      .map(({ value }) => value);

      return shuffled;
    },
    numFormat: function(num) {
        return num.toLocaleString("en-US");
    },
    calcBonus: function(type) {
      var extraBonusFactor = {
          10: 2,
          15: 3,
        };

      if ( type == 'base' ) {
        return this.gameStore.bonus.diff[this.userStore.user.options.diff].factor * this.userStore.user.options.ppr;
      } else if ( type == 'base-perfect' ) {
        var bonus = this.gameStore.bonus.diff[this.userStore.user.options.diff].factor * this.userStore.user.options.ppr * this.gameStore.bonus.perfectGame;
        if ( extraBonusFactor.hasOwnProperty(this.userStore.user.options.ppr) ) {
          bonus = bonus * extraBonusFactor[this.userStore.user.options.ppr];
        }
        return bonus;
      } else if ( type == 'immunity' ) {
        var bonus = this.gameStore.bonus.immunityScore * this.userStore.user.options.ppr;
        if ( extraBonusFactor.hasOwnProperty(this.userStore.user.options.ppr) ) {
          bonus = bonus * extraBonusFactor[this.userStore.user.options.ppr];
        }
        return bonus;
      } else if ( type == 'perfectsolve' ) {
        return this.gameStore.bonus.diff[this.userStore.user.options.diff].factor * this.gameStore.bonus.perfectSolve;
      }
    },
    calcPenalty: function() {
      return this.gameStore.penalty.diff[this.userStore.user.options.diff].tax * this.userStore.user.options.ppr;
    },
    calcAwards: function() {
      if ( !this.userStore.user.totals.perfectGames?.length ) { return false; }

      var bucket = {};
      for (let x = 0; x < this.userStore.user.totals.perfectGames.length; x++) {
        var diff = this.userStore.user.totals.perfectGames[x].diff;
        var ppr = this.userStore.user.totals.perfectGames[x].ppr;

        if ( !bucket.hasOwnProperty(diff) ) {
          bucket[diff] = {};
        }

        bucket[diff][ppr] = bucket[diff][ppr] + 1 || 1;
      }

      var awards = [];

      for (let x = 0; x < Object.keys(bucket).length; x++) {
        var k = Object.keys(bucket).toSorted()[x];
        for (let y = 0; y < Object.keys(bucket[k]).length; y++) {
          var p = Object.keys(bucket[k]).toSorted()[y];
          var qty = Math.floor( bucket[k][p] / this.gameStore.bonus.diff[k].award.wins );

          if ( qty ) {
            awards.push( {
              icon: this.gameStore.bonus.diff[k].award.icon,
              qty: qty,
              ppr: p,
            } );              
          }

          if (0) { 
            awards = [];
            var pprs = [3,5,10,15];
            for (let x = 1; x <= 3; x++) {
              for (let p = 0; p < pprs.length; p++) {
                awards.push( {
                  icon: this.gameStore.bonus.diff[x].award.icon,
                  qty:  Math.floor(Math.random() * 20) + 1,
                  ppr: pprs[p],
                }); 
              } 
            }
          }
        }
        
      }

      //this.debugOut(awards);

      return awards;
    },
    calcAwardsTotal: function() {
      var awards = this.calcAwards();
      var total = 0;
      for (let x = 0; x < awards.length; x++) {
        total = total + awards[x].qty;
      }
      return total;
    },  
    isBust: function() {
      if ( this.userStore.user.totals.puzzles > 0 && this.userStore.user.totals.score <= 0 ) {
        return true;
      } else {
        return false;
      }
    },
    playSfx: function(sfxObj,sound,options){
      if ( options && options.stop ) {
          if ( sfxObj) {
              sfxObj.stop();
          }
          return;
      }

      if ( options && options.fadeOut ) {
          if ( sfxObj) {
              sfxObj.fade(sfxObj.volume(),0,options.fadeOut);
          }
          return;
      }

      if ( sound.hasOwnProperty('files') ) {
        var sound2 = sound.files[Math.floor(Math.random()*sound.files.length)];
        sound = sound2;
      }

      if ( sound ) {
          if ( sfxObj ) {
            sfxObj.stop();
          }

          if ( options.volume ) {
            sound.volume = options.volume;
          }

          var vol = (sound.volume ? Number(sound.volume) : 1);
          if ( options.bgTune ) {
            vol = this.sfxObj.bgTuneVol * ( Number(this.globalVolume) / 100 );
          }

          sfxObj = new Howl({
              src: require('@/assets/sfx/' + sound.file),
              html5: false,
              format: ['mp3'],
              loop: sound.loop ? sound.loop : false,
              volume: vol,
              rate: sound.rate ? sound.rate : 1,
              onend: function() {
                  //this.debugOut('Finished!');
              }
          });
          if ( sound.seek ) {
              sfxObj.seek(sound.seek);
          }

          sfxObj.play();
      }

      return sfxObj;
      }, 
  },


}
</script>

<style>
html, body { height: 100%; }

#app {
  --phrase-desc-h: 1px;
  --game-summary-h: 1px;
  --safet: env(safe-area-inset-top);
  --safer: env(safe-area-inset-right);
  --safeb: env(safe-area-inset-bottom);
  --safel: env(safe-area-inset-left);
  
  font-family: 'Lilita One';
  height: 100%;
  min-height: -webkit-fill-available;
  overflow: hidden;
  background-color: gray;
  background-image: url('@/assets/gfx/lines_texture1095.jpg');
}

#app-content {
  background-color: #FFF;
}

.marquee {
  transition:0.5s;
  overflow:hidden;
}

.busted {
  background-image: url('@/assets/gfx/moldy.png');
}

.logo {
  font-family: 'Luckiest Guy', cursive;
  font-size: 4rem;
}

#globalVolume {
  width: 100px;
  left: -125px;
  top: 5px;
}

#globalVolume .vue3-slider .handle {
  background-color: var(--bs-primary);
  box-shadow: 1px -1px 4px 0px rgba(0,0,0,0.75);
}

#globalVolume .vue3-slider .track {
  box-shadow: 1px 2px 12px 0px rgba(0,0,0,0.75);
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.25s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}


</style>
