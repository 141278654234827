//setup avatar files
const avatarLib = {
    0: '<i class="game-icon game-icon-person"></i>',
    1: '<i class="game-icon game-icon-cat"></i>',
    2: '<i class="game-icon game-icon-sitting-dog"></i>',
    3: '<i class="game-icon game-icon-mouse"></i>',
    4: '<i class="game-icon game-icon-lion"></i>',
    5: '<i class="game-icon game-icon-cat"></i>',
    6: '<i class="game-icon game-icon-hawk-emblem"></i>',
    7: '<i class="game-icon game-icon-bird-twitter"></i>',
    8: '<i class="game-icon game-icon-kiwi-bird"></i>',
    9: '<i class="game-icon game-icon-hummingbird"></i>',
    10: '<i class="game-icon game-icon-seagull"></i>',
    11: '<i class="game-icon game-icon-squirrel"></i>',
    12: '<i class="game-icon game-icon-frog"></i>',
    13: '<i class="game-icon game-icon-tiger-head"></i>',
    14: '<i class="game-icon game-icon-horse-head"></i>',
    15: '<i class="game-icon game-icon-seahorse"></i>',
    16: '<i class="game-icon game-icon-snail"></i>',
    17: '<i class="game-icon game-icon-sand-snake"></i>',
    18: '<i class="game-icon game-icon-bee"></i>',
    19: '<i class="game-icon game-icon-monkey"></i>',
    20: '<i class="game-icon game-icon-gorilla"></i>',
    21: '<i class="game-icon game-icon-flatfish"></i>',
    22: '<i class="game-icon game-icon-dinosaur-rex"></i>',
    23: '<i class="game-icon game-icon-diplodocus"></i>',
    24: '<i class="game-icon game-icon-squid"></i>',
    25: '<i class="game-icon game-icon-run"></i>',
    26: '<i class="game-icon game-icon-throwing-ball"></i>',
    27: '<i class="game-icon game-icon-skateboarding-02"></i>',
    28: '<i class="game-icon game-icon-american-football-ball"></i>',
    29: '<i class="game-icon game-icon-baseball-glove"></i>',
    30: '<i class="game-icon game-icon-basketball-ball"></i>',
    31: '<i class="game-icon game-icon-hockey"></i>',
    32: '<i class="game-icon game-icon-hamburger"></i>',
    33: '<i class="game-icon game-icon-french-fries"></i>',
    34: '<i class="game-icon game-icon-full-pizza"></i>',
    35: '<i class="game-icon game-icon-super-mushroom"></i>',
};

export default {
    avatarLib
}
